module.exports = {
  siteTitle: "Akihito Ikeda", // <title>
  shortSiteTitle: "Akihito Ikeda", // <title> ending for posts and pages
  siteDescription: "Akihito Ikeda's Blog",
  siteUrl: "https://akhtikd.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "ja",

  /* author */
  authorName: "Akihito Ikeda",
  authorTwitterAccount: "https://twitter.com/akht_ikd",
  authorGithub: "https://github.com/akht",
  authorStackoverflow: "https://stackexchange.com/users/5678032/atte-juvonen?tab=accounts",
  authorLinkedin: "https://linkedin.com/in/atte-juvonen-86b886113",
  authorCodeforces: "https://codeforces.com/profile/baobab",
  authorYoutube: "https://www.youtube.com/channel/UCfHqfHoSYL0V_fXA_JQkeLw?view_as=subscriber",
  authorFacebook: "https://www.facebook.com/akht.ikd",

  /* header text */
  headerTitle: "Akihito Ikeda",
  headerSubTitle: "",

  /* manifest.json */
  manifestName: "Akihito Ikeda",
  manifestShortName: "akihitoikeda", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",
};
